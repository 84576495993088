import {
  Text,
  Img,
  Heading,
  Box,
  SimpleGrid,
  Flex,
  Button,
  Link,
  List,
  ListItem,
  ListIcon,
  VStack,
} from '@chakra-ui/react';

import React from 'react';
import { BiCircle } from 'react-icons/bi';
import { GoDotFill } from 'react-icons/go';

const information = [
  {
    title: 'SuperReturn Private Credit Asia',
    link: 'https://tinyurl.com/6c94ptw5',
    cover:
      'https://static.levocdn.com/WGLBUT82/SuperReturnsAsia-WT0jIrjfntYQd.jpeg',
    btnText: 'Register Now',
    desc: (
      <>
        <Text
          fontWeight={500}
          variant="featureText"
          textAlign="justify"
          my="16px"
        >
          SuperReturn Private Credit Asia is launching in Hong Kong this May!{' '}
          <br /> <br />
          Join 350+ private credit professionals including 120+ LPs and 150+ GPs
          for two days of meaningful connections and unmissable insights focused
          on the latest trends shaping global credit. <br />
        </Text>
        <List spacing={3}>
          <ListItem fontSize="14px" fontWeight={500} color="#3d3d5f">
            <ListIcon as={GoDotFill} color="primary" />
            The agenda covers fundraising activity, direct lending, asset-backed
            lending, mezzanine, case studies on mid-market outlooks in Asia,
            Europe, the US, and much more.
          </ListItem>
          <ListItem fontSize="14px" fontWeight={500} color="#3d3d5f">
            <ListIcon as={GoDotFill} color="primary" />
            Build valuable relationships through exclusive networking
            opportunities, including drinks receptions, LP/GP connections
            sessions, and a Women in Private Credit breakfast.
          </ListItem>
          <ListItem fontSize="14px" fontWeight={500} color="#3d3d5f">
            <ListIcon as={GoDotFill} color="primary" />
            Learn from the leaders shaping private credit. Expert speakers
            include Stepstone Group, LuminArx Capital Management LP, Aksia,
            Merit Asset Management, Zerobridge, and more.
          </ListItem>
        </List>
        <Box mt={5}>
          <Text fontWeight="bold" fontSize="14px">
            For more information or to register:
          </Text>
          <VStack align="start" spacing={2} mt={2}>
            <Text fontSize="14px">
              Call:{' '}
              <Link
                href="tel:+442080522013"
                color="#55B6A4"
                fontWeight="medium"
              >
                +44 (0) 20 8052 2013
              </Link>
            </Text>
            <Text fontSize="14px">
              Email:{' '}
              <Link
                href="mailto:gf-registrations@informaconnect.com"
                color="#55B6A4"
                fontWeight="medium"
              >
                gf-registrations@informaconnect.com
              </Link>
            </Text>
          </VStack>
        </Box>
        <Box mt={5}>
          <Text variant="featureText" textAlign="justify" fontWeight={600}>
            Date: 7th & 8th May 2025
          </Text>
          <Text
            mt="4px"
            fontWeight={600}
            variant="featureText"
            textAlign="justify"
          >
            Location: JW Marriott | Hong Kong
          </Text>
          <Flex>
            <Text
              mt="14px"
              variant="featureText"
              fontWeight={600}
              textAlign="justify"
            >
              Save 10% with code:
            </Text>
            <Text
              mt="14px"
              variant="featureText"
              fontWeight={800}
              textAlign="justify"
            >
              FKR3635IVCA
            </Text>
          </Flex>
        </Box>
      </>
    ),
  },
  {
    title: 'SuperReturn China',
    link: 'https://tinyurl.com/fhprke35',
    cover: 'https://static.levocdn.com/WGLBUT82/SRChina-xloeaCAdEL6Ce.jpeg',
    btnText: 'Register Now',
    desc: (
      <>
        <Text
          fontWeight={500}
          variant="featureText"
          textAlign="justify"
          my="16px"
        >
          SuperReturn China is launching in Hong Kong this May!
          <br /> <br />
          Join 250+ senior decision makers including 100+ LPs and 120+ GPs for
          an entire day of meaningful connections and unmissable
          insights focused on Chinese and international markets. <br />
        </Text>
        <List spacing={3}>
          <ListItem fontSize="14px" fontWeight={500} color="#3d3d5f">
            <ListIcon as={GoDotFill} color="primary" />
            The agenda offers the latest developments on the private markets
            outlook for China, fundraising strategies, AI &amp; deep tech, LP
            sentiment on Asia allocations, renewables, sustainable infra and
            energy transition, value creation, exit strategies and more!
          </ListItem>
          <ListItem fontSize="14px" fontWeight={500} color="#3d3d5f">
            <ListIcon as={GoDotFill} color="primary" />
            Build valuable relationships effortlessly through exclusive
            networking opportunities including drinks reception and LP/GP
            connections sessions.
          </ListItem>
          <ListItem fontSize="14px" fontWeight={500} color="#3d3d5f">
            <ListIcon as={GoDotFill} color="primary" />
            Learn from the visionary leaders shaping private markets and venture
            capital. Expert speakers include Shanghainvest, HarbourVest
            Partners, Albourne Partners, Vietnman Investments Group, Starquest
            Capital and more.
          </ListItem>
        </List>
        <Box mt={5}>
          <Text fontWeight="bold" fontSize="14px">
            For more information or to register:
          </Text>
          <VStack align="start" spacing={2} mt={2}>
            <Text fontSize="14px">
              Call:{' '}
              <Link
                href="tel:+442080522013"
                color="#55B6A4"
                fontWeight="medium"
              >
                +44 (0) 20 8052 2013
              </Link>
            </Text>
            <Text fontSize="14px">
              Email:{' '}
              <Link
                href="mailto:gf-registrations@informaconnect.com"
                color="#55B6A4"
                fontWeight="medium"
              >
                gf-registrations@informaconnect.com
              </Link>
            </Text>
          </VStack>
        </Box>
        <Box mt={5}>
          <Text variant="featureText" textAlign="justify" fontWeight={600}>
            Date: 6th May 2025
          </Text>
          <Text
            mt="4px"
            fontWeight={600}
            variant="featureText"
            textAlign="justify"
          >
            Location: JW Marriott | Hong Kong
          </Text>
          <Flex>
            <Text
              mt="14px"
              variant="featureText"
              fontWeight={600}
              textAlign="justify"
            >
              Save 10% with code:
            </Text>
            <Text
              mt="14px"
              variant="featureText"
              fontWeight={800}
              textAlign="justify"
            >
              FKR3624IVCA
            </Text>
          </Flex>
        </Box>
      </>
    ),
  },
  {
    title: 'SuperReturn International Berlin',
    link: 'https://informaconnect.com/superreturn-international/',
    cover:
      'https://static.levocdn.com/WGLBUT82/SuperReturnsInternationalBerlin-pKk1luEBA6Lb.jpeg',
    btnText: 'Register Now',
    desc: (
      <>
        <Text
          fontWeight={500}
          variant="featureText"
          textAlign="justify"
          my="16px"
        >
          Private capital’s most senior gathering. <br />
          The most senior. The most global. The most LPs. The most connections.
        </Text>
        <Text
          fontWeight={500}
          variant="featureText"
          textAlign="justify"
          my="16px"
        >
          Don’t miss your chance to…
        </Text>
        <List spacing={3}>
          <ListItem fontSize="14px" fontWeight={500} color="#3d3d5f">
            <ListIcon as={GoDotFill} color="primary" />
            Meet more than 5,500+ senior decision-makers
          </ListItem>
          <ListItem fontSize="14px" fontWeight={500} color="#3d3d5f">
            <ListIcon as={GoDotFill} color="primary" />
            Connect with 1,800+ powerful LPs with $50T AUM, and 2,700+ GPs
          </ListItem>
          <ListItem fontSize="14px" fontWeight={500} color="#3d3d5f">
            <ListIcon as={GoDotFill} color="primary" />
            Hear from an incomparable line-up of 500+ industry heavyweight
            speakers
          </ListItem>
          <ListItem fontSize="14px" fontWeight={500} color="#3d3d5f">
            <ListIcon as={GoDotFill} color="primary" />A truly global gathering
            from 70+ countries
          </ListItem>
          <ListItem fontSize="14px" fontWeight={500} color="#3d3d5f">
            <ListIcon as={GoDotFill} color="primary" />
            View the delegate list &amp; arrange invaluable meetings ahead of
            time
          </ListItem>
        </List>
        <br />
        <Text
          fontWeight={500}
          variant="featureText"
          textAlign="justify"
          my="16px"
        >
          Get the inside track on innovation, ESG, growth, private debt, value,
          secondaries, investing in sport, private wealth, regeneration and
          return <br />
          LPs already confirmed include AP1, CDPQ, GIC, Helmsley Charitable
          Trust, OTPP, Zurich Insurance Group and many more.
        </Text>
        <Box mt={5}>
          <Text variant="featureText" textAlign="justify" fontWeight={600}>
            Date: 2nd - 6th June 2025
          </Text>
          <Text
            mt="4px"
            fontWeight={600}
            variant="featureText"
            textAlign="justify"
          >
            Location: InterContinental Hotel, Berlin
          </Text>
          <Flex>
            <Text
              mt="14px"
              variant="featureText"
              fontWeight={600}
              textAlign="justify"
            >
              Save 10% with code:
            </Text>
            <Text
              mt="14px"
              variant="featureText"
              fontWeight={800}
              textAlign="justify"
            >
              FKR3584IVCA
            </Text>
          </Flex>
        </Box>
      </>
    ),
  },
  {
    title: 'Global Private Capital Conference',
    link: 'https://cvent.me/e5Mv3G',
    cover:
      'https://static.levocdn.com/WGLBUT82/GPCConference2025SocialPromo-dO45wphYsZSd.png',
    btnText: 'Register Now',
    desc: (
      <>
        <Text
          fontWeight={500}
          variant="featureText"
          textAlign="justify"
          my="16px"
        >
          Join us at the GPC Conference 2025 in New York City from 7-9 April
          2025, at Convene 360 Madison. <br /> The Global Private Capital
          Conference is a must-attend event for investors, LPs and entrepreneurs
          looking to gain insights into global private capital and trends and
          opportunities across Asia, Latin America, Africa, CEE and the Middle
          East. <br />
          Participants include a curated group of family offices, endowments,
          pensions, sovereign wealth funds, development finance institutions,
          experienced fund managers and new investment teams active across
          private equity, venture capital, impact, infrastructure and credit. We
          look forward to insightful discussions and meaningful connections.
        </Text>
        <Box>
          <Text variant="featureText" textAlign="justify" fontWeight={600}>
            Date: 7th & 9th April 2025
          </Text>
          <Text
            mt="4px"
            fontWeight={600}
            variant="featureText"
            textAlign="justify"
          >
            Location: New York City
          </Text>
          <Text
            mt="14px"
            variant="featureText"
            fontWeight={800}
            textAlign="justify"
          >
            IVCA Members get 15% Discount
          </Text>
        </Box>
      </>
    ),
  },
];

const PartnerEvents: React.FC = () => (
  <Flex
    justifyContent={{ xs: 'center', lg: 'flex-start' }}
    pl={{ xs: '0%', lg: '10%', xl: '8%' }}
    bgColor="bg.lightWhite"
    py="5px"
  >
    <Box id="media-coverage" pt={{ xs: '60px' }} maxW="container.2xl" w="90%">
      <Heading
        p="0"
        fontSize={{ xs: '22px', sm: '28px', md: '30px', lg: '42px' }}
        lineHeight={{ xs: '30px', md: '40px', lg: '54.6px' }}
        fontWeight="800"
      >
        Partner Events
      </Heading>
      <SimpleGrid
        w="100%"
        mt="24px"
        gap="2rem"
        columns={{ xs: 1, md: 2, xl: 3 }}
      >
        {information?.map((info) => (
          <Box
            boxShadow="md"
            borderRadius="xl"
            overflow="hidden"
            key={info?.title}
          >
            <Img height="250px" w="100%" src={info.cover} alt={info.title} />
            <Box p="1rem">
              <Heading as="h3" variant="featureTitle">
                {info.title}
              </Heading>
              <Box
                overflowY="scroll"
                css={{
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                {typeof info?.desc === 'string' ? (
                  <Text variant="featureText" textAlign="justify">
                    {info?.desc}
                  </Text>
                ) : (
                  info?.desc
                )}
              </Box>
              <Link href={info.link} aria-label="learn-more" target="_blank">
                <Button
                  colorScheme="primary"
                  aria-label="learn-more"
                  w={{ xs: '175px' }}
                  h={{ xs: '30px', md: '40px' }}
                  mt="15px"
                >
                  {info.btnText}
                </Button>
              </Link>
            </Box>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  </Flex>
);

export default PartnerEvents;
